.signup-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  //   background-image: url("../../assets/images/backgrounds/vector1.jpg");
  background-size: cover;
}

.signup-card {
  width: 90%;
  max-width: 400px; /* Adjust the max-width as needed */
  padding: 20px;
  background: rgba(0, 0, 0, 0.55);
  backdrop-filter: blur(10.5px);
  -webkit-backdrop-filter: blur(10.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  transform: translateZ(0);
  will-change: opacity;
  box-shadow: 14px 14px 22px rgba(0, 0, 0, 0.7);
  color: #fff;

  &:hover {
    transform: scale(1); /* Set the scale to 1 or the default value */
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: initial !important;
  }

  /* Disable Firefox autofill styles */
  &:required:-moz-ui-invalid {
    box-shadow: none;
  }
}

form {
  display: flex;
  flex-direction: column;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Adjust the space between form elements */
  color: #fff;

  .form-control {
    background-color: transparent !important;
    color: white;
    border: 1px solid #fff; /* Add a border for visibility */
  }
}

.form-input {
  width: 100%;
  max-width: 150px; /* Adjust the max-width as needed */
  margin: 0 auto; /* Center the form input elements */
  //   background-color: transparent;
  color: white;
}

#signin-btn {
  margin-top: 15px; /* Adjust the margin as needed */
}

.mb-3 {
  margin-top: 20px; /* Adjust the margin as needed */
  text-align: center;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .signup-card {
    width: 100%;
  }
}
