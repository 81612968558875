.error-info-alert {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: solid 1px #fff;

  text-align: center;
  background: rgba(255, 0, 0, 0.576);
  // box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
  backdrop-filter: blur(10.5px);
  -webkit-backdrop-filter: blur(10.5px);
}
