.new-arrivals-card{
    height: 300px;
    width: auto;
    // border-radius: 10px;
    // background-color: rgba(161, 161, 161, 0.521);
    // border: #ffffff solid 1px;

    // background: rgba( 255, 255, 255, 0.5 );
    // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    // backdrop-filter: blur( 7.5px );
    // -webkit-backdrop-filter: blur( 7.5px );
    // border-radius: 10px;
    // border: 1px solid rgba( 255, 255, 255, 0.18 );

    background: rgba( 0, 0, 0, 0.55 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 10.5px );
    -webkit-backdrop-filter: blur( 10.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );

    transform:scale(1, 1);
    transition: 0.0s;
  
    &:hover{
    overflow: hidden;
    transform: scale(1.00);
    }
}


.new-arrivals-card-background{
    background-color: #ffffff;
    // background-image: linear-gradient(315deg, #000000 0%, #414141 74%);
    height: inherit;
    border-radius: inherit;
    position: relative;
}

// /////////////////////////////////////////////////



.new-arrivals-card-body{
    height: 90%;
    width: 100%;
    position: absolute;
    margin-top: 2.5%;

    
}

.new-arrivals-title{
    font-size: large;
    font-weight: 500;
    margin: 0px;
    vertical-align: middle;

}

.new-arrivals-title-neumorph{
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}

.new-arrivals-carousel{
    justify-content: baseline;
    width: 100%;
    height: 90%;
    padding-top: 0.5rem;
    // padding-bottom: 1rem;
}


.rec.rec-item-wrapper{
    height: 13.5rem;
    align-items: center;
    padding: 0px 0px;
}

.new-arrivals-carousel.rec-pagination{
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    // align-items: center;
    // justify-content: center;
}

.rec.rec-pagination{
    margin: 0px 0px 0px 0px;

}

.rec.rec-slider-container{
    margin-left: -10px;
    margin-right: -10px;
}


.lcUbOd{    
    background-color: #ffffff;
}

.rec.rec-arrow {
    border-radius: 0;
    background-color: #ffffff00;//#3a44a96b;
    border: none;
    box-shadow: none;
    height: 50%;
    width: 1%;

}

.rec.rec-dot{
    border-radius: 1;
    border-color: #ffffff;
}

.rec-dot.rec-dot_active{
    background-color: #ffffff;
    box-shadow: none;
}


@media screen and (max-width: 1000px) {
    .new-arrivals-card{
        height: 200px;

    }
}