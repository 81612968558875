.directory-categories-container {
  width: 90%;
  height: fit-content;
}

.category-title-card {
  width: 100%;
  height: 50px;
  margin: auto;
  margin-bottom: 1%;

  justify-content: center;

  background: rgba(0, 0, 0, 0.55);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10.5px);
  -webkit-backdrop-filter: blur(10.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.category-title-card {
}

.category-cards-container {
  width: 100%;
  height: fit-content;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1%;
  row-gap: 1rem;
}

.category-card {
  flex-grow: 1;
  height: 150px;
  width: 200px;
  display: flex;
  align-items: center;
  //   border: 1px #fff solid;
}

.category-image {
  width: 100%;
  height: inherit;
  object-fit: cover;
  border-radius: inherit;
  overflow: hidden;
}

.category-cardbody {
  height: 100%;
  width: 100%;
  text-align: center;
  padding: 5%;
  top: 40%;
}

.category-card-title {
  align-items: center;
  text-align: center;
  justify-content: center;
  word-wrap: break-word;
  //   line-height: 1200%;
  color: #fff;
}
