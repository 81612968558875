.carousel,
.slide,
.carousel .carousel-inner,
.carousel .carousel-item,
.carousel .carousel-item img,
.carousel .carousel-control {
  border-radius: 10px;
  overflow: hidden;
  // border: solid 0.1px #fff;
}

.promo-carousel {
  width: 90%;
  padding: 2% 0% 2% 0%;
}
.promo-carousel img {
  image-rendering: optimizeQuality;
  height: fit-content;
  // max-height: 450px;
}
