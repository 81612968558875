.product-search-container {
  width: 100%;
  height: 100%;
  margin-top: 1%;
}

.product-text {
  color: #ddd;
  text-align: center;
}

.product-search-card {
  width: 90%;
  margin: auto;

  // background: rgb(0, 0, 0);
  // opacity: 0.8;

  background: rgba(0, 0, 0, 0.55);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(11.5px);
  -webkit-backdrop-filter: blur(11.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  transform: translateZ(0);
  will-change: opacity;
  will-change: filter;

  overflow: visible;
}

.product-search-cardbody {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1.5%;
}

.product-search-card:hover {
  transform: none;
}
//////////////////////////////////////////////////////////////////////

.filter-open-button {
  position: absolute;
  // right: -17px;
  left: -18px;
  width: 35px;
  height: 120px;
  padding: 4px;
  margin: 0px;
  z-index: 3;
}

#filter-tooltip {
  padding: 20px;
  border: 1px solid #ddd;
  font-weight: 500;
}

.search-filter-container-test {
  // this is stick version
  display: flex;
  position: sticky;
  align-items: center;

  height: 38rem;
  width: 3px;
  // max-height: 600px;
  left: -30px;
  top: 10%;
  border: 1px solid #ddd;
  border-radius: 20px;
  z-index: 1;
}

.search-filter-container-test-2 {
  // this is sticky version
  display: flex;
  position: absolute;
  align-items: center;

  height: inherit;
  max-height: 100%;
  width: 325px;
  // border: 1px solid #ddd;
  border-radius: inherit;
  z-index: 2;
}

////////////////////////////////////////
.search-results-container {
  display: flex;
  // position: absolute;
  justify-content: space-around;
  // align-content:space-around;

  flex-wrap: wrap;
  overflow-y: scroll;

  height: 100%;
  width: 100%;
  // padding: 0rem 1rem;
  margin-left: 2rem;

  border: 1px solid #ddd;
  border-radius: 1rem;
  color: #ddd;
}

.search-results-container::-webkit-scrollbar {
  width: 5px;
  border-radius: 1rem;
}
.search-results-container::-webkit-scrollbar-track {
  margin: 0.5rem 0px;
  overflow: hidden;
  padding: 5px 0px;
}

.search-results-container::-webkit-scrollbar-thumb {
  border: 4px solid rgba(184, 7, 7, 0.041);
  background-color: #ddd;
  border-radius: 5px;
}

////////////////////////
.search-page-pagination-container {
  margin: 2% auto;
  width: fit-content;
  height: 20%;
  padding: 10px;

  background: rgba(0, 0, 0, 0.55);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(11.5px);
  -webkit-backdrop-filter: blur(11.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  transform: translateZ(0);
  will-change: opacity;
}

.pagination {
  width: 100%;
  margin: auto;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  row-gap: 15px;

  cursor: pointer;
}

.pagination a {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #fff;
  color: #ddd;
}

.pagination a:hover {
  color: #007bff !important;
  background-color: #ddd;
}

.pagination__link {
  display: inline-block;
  align-items: center;
  width: 100px;
  font-weight: bold;
  text-align: center;
  margin: 0 1rem;
}

.pagination__link:hover {
  background-color: #ddd !important;
  color: #007bff !important;
}

.pagination__link--active a {
  color: #fff;
  background: #ddd;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}

.filter-order-selection {
  width: 85%;
  color: #ddd;
  background-color: transparent;
  border-radius: 7px;
  border: #ddd solid 1px;
  // border-style: solid 1px white;
}

.rstm-search {
  color: #ddd;
}

.rstm-tree-item {
  align-items: center;
}

.rstm-toggle-icon {
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  margin-right: 1rem !important;
  justify-content: center;
}

.rstm-toggle-icon:hover {
  border: 1px solid #007bff;
  border-radius: 100px;
}

.rstm-toggle-icon-symbol {
  position: relative;
  font-size: 1.7rem !important;
  justify-content: center !important;
  margin-left: 8px;
}

.filter-item {
  color: #ddd;
}

#filter-selection-statements {
  font-size: small;
  color: #ddd;
}

.pagination-buttons {
  border-radius: 10px;
  background-color: transparent;
  border: solid 1px #ddd;
}
#filter-title {
  color: #ddd;
  font-weight: 500;
}

.react-paginate-page-nums {
  color: #ddd;
}
.react-paginate-page-active {
  color: #007bff !important;
  font-weight: 600;
}
