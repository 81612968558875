.order-history-title {
  color: white;
}

.order-history-page-container {
  margin: auto;
  width: 90%;

  background: rgba(0, 0, 0, 0.55);
  backdrop-filter: blur(10.5px);
  -webkit-backdrop-filter: blur(10.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  transform: translateZ(0);
  will-change: opacity;
  box-shadow: 14px 14px 22px rgba(0, 0, 0, 0.7);
  padding: 2em;
}

.orders-table th,
.orders-table td {
  padding: 10px; /* Add padding to each cell */
  color: white;
}

.order-data-field {
  color: white;
  padding: 1em 1em 1em 1em;
}

@media (max-width: 400px) {
  .order-data-field:first-child {
    max-width: 40px;
    word-wrap: break-word;
  }

  .order-data-field {
    color: white;
    border: 1px solid white;
    border-radius: 5px;
  }
  .orders-table th,
  .orders-table td {
    padding: 0px; /* Add padding to each cell */
  }
  .order-history-page-container {
    padding: 2em 0 2em 0;
  }
}
