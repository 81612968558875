.product-page-container {
  padding-top: 2%;
  margin: auto;
  height: 100%;
  width: 90%;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}

.fade {
  opacity: 1 !important;
}

.product-page-product-display-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 2%;
  justify-content: space-between;
  gap: 2rem;
  flex-grow: 1;
  align-items: stretch;
}

.product-page-carousel-container {
  background: rgba(0, 0, 0, 0.55);
  backdrop-filter: blur(10.5px);
  -webkit-backdrop-filter: blur(10.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  // border: 1px solid #fff;
  flex-grow: 1;
  flex-basis: 56%;
}

// .carousel-image {
//   width: 750px;
//   height: 500px;
//   padding: 15px;
// }

.thumbnails {
  display: flex;
  justify-content: center;
  width: 100%;
  max-height: 80px;
}

.thumbnail {
  background-color: #fff;
  border-radius: 10px;
  max-height: 80px;
  object-fit: contain;
  padding: 5px;
}

.dot {
  background-color: transparent;
  border: solid 1px #fff;
}
// .dot active
.active {
  background-color: #fff;
  border: solid 1px #fff;
}

.active-thumbnail {
  border: solid 2px #fff;
}

////////////////////////////////////////////////////
///
///
#purchase-card {
  margin: auto;
  max-width: 22rem;
  min-height: 650px;
  flex-grow: 1;
  height: 100%;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  align-content: space-between;

  justify-content: center;
  // border: 1px solid #fff;

  padding: 2rem;
}

#product-page-title {
  font-size: 35px;
  color: #fff;
  text-align: center;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

#product-page-stock-indicator {
  text-align: center;
  width: 100%;
}

#variant-title {
  font-size: small;
  margin-bottom: -15px;
  color: #fff;
}

#product-page-ratings-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

// #product-page-product-description {
//   width: 100%;
//   height: fit-content;
//   font-size: x-small;

//   border: solid 1px #fff;
//   line-height: normal;
//   border-radius: 10px;
// }

#product-page-rating-star {
  width: 100%;
  margin: auto;
}

.empty-icons {
  color: rgb(152, 152, 120);
}

#product-page-formselect {
  background-color: transparent;
  color: #fff;
}

option {
  color: black;
}

#product-page-quantity-inputgroup {
  text-align: center;
  width: 55%;
}

#product-page-quantity-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.quantity-input {
  background-color: transparent;
  color: #fff;
}

.quantity-input:focus {
  background-color: transparent;
  color: #fff;
}

.product-page-item-variations-container {
  width: 100%;
  height: 20%;
  border: solid 1px #fff;
  border-radius: 10px;
}

#product-page-price {
  width: fit-content;
  padding: 3%;
  color: #fff;
  border: solid 1px #fff;
  border-radius: 15px;
  text-align: center;
}

#product-page-cart-btn {
  width: 100%;
}

.item-info-card {
  //   height: 500px;
  //   width: 100%;
  //   margin: 0%;
  background: rgba(0, 0, 0, 0.55);
  // box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
  backdrop-filter: blur(10.5px);
  -webkit-backdrop-filter: blur(10.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

/////////////////////////////////////////////////////////////////////////////////
///
.product-page-technicals-container {
  margin-top: 2%;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Collapsible {
  width: 100%;
}

.product-page-collapsible-section {
  width: 100%;
  //   max-width: 300px;
  color: #fff;

  background: rgba(0, 0, 0, 0.55);
  //   box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
  backdrop-filter: blur(10.5px);
  -webkit-backdrop-filter: blur(10.5px);
  border-radius: 10px;
  border: 1px solid #fff;
}

.product-page-collapsible-info {
  width: 100%;
  height: fit-content;
  padding: 1.1rem;
  margin-bottom: 3%;

  color: #fff;
  background: rgba(0, 0, 0, 0.55);
  // box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
  backdrop-filter: blur(10.5px);
  -webkit-backdrop-filter: blur(10.5px);
  border-radius: 10px;
  border: 1px solid #fff;
}

.product-page-collapsible-column {
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
}

.product-page-collapsible-headers {
  color: #fff;
  font-weight: 500;
}

.product-page-collapsible-text {
  color: #fff;
  text-align: center;
  margin: auto;
}
