.collapsible-filter-container {
  // height: 40%;
  border: solid #ddd 1px;
  border-radius: 10px;
  overflow: hidden;
  padding: 0%;
  margin: 5% 0%;
}

.collapsible-filter-btn {
  color: #ddd;
  width: 100%;
  margin: -1px;
}

.brand-item {
  padding: 0%;
  margin: 2% 0% 2% 7%;
  padding-right: 8%;
  color: #ddd;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: rgb(0, 228, 49);
  border-radius: 10px;
}

/////////////////////////////////////////////@at-root
///
/* Remove default bullets */
ul,
#myUL {
  list-style-type: none;
  padding: 5px;

  width: 100%;
}

/* Remove margins and padding from the parent ul */
#myUL {
  margin: 0;
  padding: 0;
}

/* Style the caret/arrow */
.caret {
  color: #fff;
  cursor: pointer;
  user-select: none; /* Prevent text selection */
  padding: 5px;
}
.caret:hover {
  background-color: rgba(255, 255, 255, 0.135);
  border-radius: 5px;
  padding: 5px;
}

/* Create the caret/arrow with a unicode, and style it */
.caret::before {
  content: "\25B6";
  color: #fff;
  display: inline-block;
  margin-right: 6px;
}

/* Rotate the caret/arrow icon when clicked on (using JavaScript) */
.caret-down::before {
  color: #fff;
  transform: rotate(90deg);
}

/* Hide the nested list */
.nested {
  background-color: transparent;
  color: #fff;
  border: none;
  display: none;
}
.li-category {
  margin-left: 5%;
  text-align: start;
  color: #fff;
}

.li-category:hover {
  background-color: rgba(255, 255, 255, 0.135);
}

/* Show the nested list when the user clicks on the caret/arrow (with JavaScript) */
.active {
  display: block;
}

.rstm-search {
  margin: 0.5rem 0px;
  background-color: transparent;
  border-radius: 7px;
  border: bold 2px;
  width: 90%;
}
.rstm-tree-item-group {
  display: flex;
  flex-direction: column;
  margin: 0;
}
.rstm-tree-item {
  text-align: left;
  display: -webkit-flex;
  font-size: medium;
  color: #fff;
}

.rstm-tree-item:hover {
  display: -webkit-flex;
  font-size: medium;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.135);
}
.rstm-tree-item:active {
  display: -webkit-flex;
  font-size: medium;
  color: #fff;
  background-color: rgba(221, 221, 221, 0.45);
}

.rstm-toggle-icon {
  margin-left: -0.5rem;
  padding-right: 0.5rem;
}
