.navbar-div {
  // width: 100%;
  // height: 400px;
  display: inline;
  top: 0;
  justify-content: center;
  background-color: #ffffff;
  color: #ffffff;
  padding-top: 200px;
}

.logo-img-container {
  margin-bottom: 15px;
}

.logo-img {
  display: flex;
  height: 30%;
  border-radius: 20px;

  background: rgba(0, 0, 0, 0.55);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10.5px);
  -webkit-backdrop-filter: blur(10.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  transform: translateZ(0);
  will-change: opacity;
}

.navbar-card {
  z-index: 990;
  display: flex;
  width: fit-content;
  height: inherit;
  border-radius: 50px;
  top: 0;
  position: sticky;
  margin: 10px 20px;
  overflow: visible;

  // background: rgba( 0, 0, 0, 0.6 );
  // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  // backdrop-filter: blur( 5px );
  // -webkit-backdrop-filter: blur( 5px );
  // border: 1px solid rgba( 255, 255, 255, 0.18 );

  // background: rgba( 255, 255, 255, 0.5 );
  // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  // backdrop-filter: blur( 7.5px );
  // -webkit-backdrop-filter: blur( 7.5px );
  // border-radius: 10px;
  // border: 1px solid rgba( 255, 255, 255, 0.18 );

  background: rgba(0, 0, 0, 0.55);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10.5px);
  -webkit-backdrop-filter: blur(10.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  transform: translateZ(0);
  will-change: opacity;

  transform: scale(1, 1);
  transition: 0s;

  &:hover {
    overflow: visible;
    transform: scale(1);
  }
}

.sticky-top {
  display: flex;
  width: max-content;
  border-radius: 50px;
  margin: 10px 0px 10px 0px;
}

.navbar-collapse {
  width: max-content;
}

.nav-item {
  position: relative;
  padding: 0px 4px;
}

.navbar-nav {
  display: flex;
  position: fix;
  justify-content: center;
}

.nav-btn {
  padding: 5px 15px 5px 15px;
  color: #ffffff;
  font-size: medium;
  margin: 0% 0.5% 0% 0.5%;
  width: 100%;
  height: 40px;
  white-space: nowrap;
}

.button-icon {
  margin: 0px 10px 0px 0px;
}

.shopping-cart > .button-icon {
  margin: 0px 0px 0px 0px;
}

.search-box-container {
  width: 50px;
  height: 40px;
}

#badge-cart-num {
  border-radius: 30px;
  margin-left: 10px;
}

.navbar-account-dropdown {
  position: relative;
}

.dropdown {
  position: relative;
}

////////////////////////////////////

#country-flag {
  border-radius: 10px;
  padding: 0px;
  margin-right: 2px;
}
