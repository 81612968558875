.contact-page-container {
  margin: auto;
  display: flex;
  height: fit-content;
  width: 90%;
  padding: 3% 5% 3% 3%;
  margin-top: 2%;

  background: rgba(0, 0, 0, 0.55);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(11.5px);
  -webkit-backdrop-filter: blur(11.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.contact-page-store-img {
  //   padding: ;
  margin: auto 50px auto 0px;
  width: 50%;
  border-radius: 200px;
}

.contact-page-card {
  border: 1px solid #fff;
  border-radius: 10px;

  width: 100%;
  height: 100%;
  padding: 10px;
  margin: auto;
}
.contact-info-col {
}
#contact-page-title {
  font-weight: 700;
  text-align: center;
  color: #fff;
  margin: auto;
}

#contact-page-hr {
  color: #fff;
  background-color: #fff;
  height: 0.2px;
}

#contact-page-subtitle {
  font-weight: 400;
  text-align: center;
  color: #fff;
  margin: auto;
}
