.directory-container{
  display: inline-block;
  width: 90%;

}

.directory-title{
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 0px 0% 0px 0%;

}

.title-card.card{

  margin: 0%;
  justify-content: center;
  align-items: center;

  // background: rgba( 255, 255, 255, 0.5 );
  // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  // backdrop-filter: blur( 7.5px );
  // -webkit-backdrop-filter: blur( 7.5px );
  // border-radius: 10px;
  // border: 1px solid rgba( 255, 255, 255, 0.18 );

  background: rgba( 0, 0, 0, 0.55 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 10.5px );
  -webkit-backdrop-filter: blur( 10.5px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.directory-menu {
    width: 100%;
    display: inline-grid;
    justify-content: center;
    align-items: center;
    padding: 20px 5% 10px 5%;

    grid-template-columns: 4;

    gap: 0.6rem; //gap between boxes top 
    // grid-column-gap: 10px;
    // grid-row-gap: 10px;
    
    grid-template-columns: 1fr ;

  }

.card-title-text{
  line-height: 1.5%;
  color: #fff;
}



  @media screen and (min-width: 600px) {
    .directory-menu{
      grid-template-columns: repeat(auto-fit, minmax(14rem, 16rem));
    }
  }


