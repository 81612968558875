.background-image {
  height: 100%;
  width: 100%;
  z-index: 0;
  background-repeat: repeat;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.site-container {
  width: 100%;
  height: 100%;
  padding-top: 15px;
  padding-bottom: 30px;
}

.maintenance-alert {
  margin: auto;
  text-align: center;
  width: 90%;
}
