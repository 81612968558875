.homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 0px 0px;
    // background-color: #ffffff; //other good colours #3B8BEB, #C4DBF6, 77A6F7

  }

.top-image{
  width: 100%;
  height: auto;

}
  
