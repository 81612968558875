////////////////////////////////////////////////
.search-item-card {
  height: 200px;
  width: 300px;
  margin: 0.5rem;
  border-radius: 0.6rem;
  // margin: 0.5rem;

  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(3.5px);
  -webkit-backdrop-filter: blur(3.5px);
  border-radius: 10px;
  border: 0px solid rgba(255, 255, 255, 0.18);

  transform: translateZ(0);
  will-change: opacity;
}

.search-item-cardbody {
  max-height: 200px;
  display: flex;
  padding: 0px;
  overflow: hidden;
  border-radius: inherit;
}

.search-item-card:hover {
  transform: none;
}

.search-item-cardbody-container {
  display: flex;
}
/////////////////////////////////////////////////////////////////////////
///
.product-card-overlay {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 1px 8px 32px 0 rgba(65, 66, 83, 0.37);
  backdrop-filter: blur(3.5px);
  // -webkit-backdrop-filter: blur(10.5px);
  border-radius: 10px;
  border: 0px solid rgba(255, 255, 255, 0.18);

  transform: translateZ(0);
  will-change: opacity;

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 0;
  height: 100%;
  z-index: 15;

  transform: scaleX(0.95);
  transform-origin: left;

  transition: linear 0s;
}

.product-card-addinfo {
  // white-space: nowrap;
  white-space: pre-wrap;
  line-height: normal;
  color: black;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  margin-top: 10%;
  top: 50%;
  left: 50%;
  height: 140px; //used to be 75%
  width: 165px; //used to be 90%
  padding: 0% 17% 0% 0%;
  z-index: 15;

  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  // max-width: 30ch;

  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.leftside-product-item:hover .product-card-overlay {
  max-width: 100%;
  width: inherit;
  border-radius: inherit;
  border-radius: 10px 50% 50% 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

////////////////////////////////////////////////////
.leftside-product-item {
  width: 66%;
  height: inherit;
  background-color: rgba(255, 255, 255, 1);
  z-index: 10;
  border-radius: 0% 50% 50% 0%;
  // position: absolute;  unblock to restore left side

  // border: 10px solid rgba(153, 153, 153, 0);
  // background-clip: padding-box;
}

.product-card-image {
  padding: 21% 0% 5% 0%;
  height: 100%;
  width: 100%;
  border-radius: 0px 50% 50% 0px;
  object-fit: contain;
  // margin-left: 1rem;
}
#corner-product-card-div {
  position: absolute;
  height: 20%;
  width: 40%;
  top: 5px;
  left: 10px;
  z-index: 99;

  clear: both;
  text-align: right;
}

.corner-product-card-logo {
  // position: absolute;

  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: 0%;
}

.product-card-badge {
  position: absolute;
  height: 20px;
  width: 50px;
  top: 50px;
  left: 10px;
  z-index: 12;

  padding: 3px;
  margin: 0px;
}

.product-card-badge-fuel {
  position: absolute;
  height: fit-content;
  width: fit-content;
  top: 75px;
  left: 10px;
  z-index: 12;

  flex-wrap: wrap;
  word-wrap: normal;
  padding: 3px;
  margin: 0px;
}

.rightside-product-item {
  display: flex;
  min-height: 200px;
  flex-direction: column;
  justify-content: start;
  width: 34%;
  height: inherit;
  padding: 5px 5px 5px 0px;
}

.floor-product-card-logo {
  height: 200px;
  width: 50%;
}

.search-product-title {
  display: inline-block;
  height: 100px;
  width: 95px;
  text-align: center;
  vertical-align: middle;
  margin: 0px auto;
  padding: 0px 2px;

  color: #ddd;
  font-weight: 400;
  font-style: oblique;
  font-size: small;

  text-overflow: ellipsis;

  overflow: hidden;
}

.product-card-ratings-span {
  margin: auto;
  margin-top: 0;
  margin-bottom: 0%;
  // margin: 0%;
  // text-align: center;
  // padding: 15px;
}

.p-size-text {
  text-align: center;
  padding-top: 0px;
  margin: 0px;
  // text-decoration: underline;
}

.product-size-variants-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 30px;
  flex-wrap: wrap;
}

.product-card-size-badge {
  margin: 2px;
  padding: 4px;
  height: fit-content;
}

/////////////////////

.cart-price-container {
  padding: 3% 0%;
  height: 55%;
  width: 100%;

  // margin-left: -0px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;

  overflow: visible;
}

.product-listing-cart-button {
  padding: 8px;
  height: 35px;
  min-width: 90px;
  margin-top: 5px;
  z-index: 10;
}

.product-listing-cart-button-no-stock {
  padding: 8px;
  height: 35px;
  margin-top: 5px;
  min-width: 100px;

  font-size: x-small;
  font-weight: 700;

  z-index: 10;
}

.product-add-cart-icon {
  width: 5em;
  height: 1.3em;
}

.product-listing-price-badge {
  margin: 0;
  padding: 5px;
  height: fit-content;
  width: fit-content;
  display: flex;
  text-align: center;
  font-size: 15px;
  font-weight: 900;

  color: #ddd;
}

#product-listing-call-for-price {
  font-size: 12px;
}

.product-listing-price-cardbody {
  padding: 5px;
  margin: 0px;
  font-weight: 900;
  color: #ddd;
}

.product-listing-price-card {
  width: 75px;
  height: 35px;
  justify-content: center;
  // background-color: #ddd;
}
.product-listing-price-card:hover {
  transform: none;
}
