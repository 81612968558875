.search-filter-card {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 19;
  padding: 0px;

  background: rgba(0, 0, 0, 0.705);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(11.5px);
  -webkit-backdrop-filter: blur(11.5px);
  border-radius: 20px;
  // border: 1px solid rgba( 255, 255, 255, 0.18 );
  border: 1px solid #ddd;
}

.search-filter-card:hover {
  transform: none;
}

.search-filter-cardbody {
  position: absolute;
  width: 100%;
  height: 99%;
  padding: 7%;
  margin: auto;

  overflow-y: scroll;
  overflow-x: hidden;
}

.search-filter-cardbody::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
  border-radius: 5px;
}

.search-filter-cardbody::-webkit-scrollbar-track {
  // background: #ddd;        /* color of the tracking area */
  // border-radius: 5px;
}

.search-filter-cardbody::-webkit-scrollbar-thumb {
  background-color: #ddd; /* color of the scroll thumb */
  border-radius: 50px; /* roundness of the scroll thumb */
  border: 1px solid rgba(221, 221, 221, 0.301); /* creates padding around scroll thumb */
}

.reset-filters-button {
  width: 100%;
  font-size: large;
  padding: 7px;
  // margin: 5px 0px ;
}

.hr {
  visibility: visible;
  margin: 25px;
  width: 90%;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0)
  );
}

#filter-title {
  padding-top: 25px;
  color: #ddd;
  font-weight: 400;
}

.react-simple-star-rating {
  // padding: -15px;
}

.collapsible {
  margin: 15px 0px;
}

.brands-container {
  padding: 15px 0px 15px 0px;
}
