.payment-method-container {
  margin-top: 2em;
  text-align: center;
  width: 90%;
  max-width: 400px; /* Adjust the max-width as needed */
  padding: 30px;
  background: rgba(0, 0, 0, 0.55);
  backdrop-filter: blur(10.5px);
  -webkit-backdrop-filter: blur(10.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  transform: translateZ(0);
  will-change: opacity;
  box-shadow: 14px 14px 22px rgba(0, 0, 0, 0.7);
  color: #fff;
}

.payment-method-title {
  color: #fff;
}

.payment-method-form {
  margin-top: 3em;
  background-color: transparent !important;
  color: white;
}

.btn-continue {
  background-color: transparent;
}
